import { graphql } from 'gatsby';
import { StructuredText } from 'react-datocms';

import { PageWrapper } from 'components/Layout/PageWrapper';

import { VerticalContainer, TextContainer } from 'components/UI/Containers';
import { HeadingBig } from 'components/UI/Headings';

const OtherPagesTemplate = ({
  data: {
    datoCmsOtherPage: { seo, title, structuredBody },
  },
  pageContext,
}) => (
  <PageWrapper
    pageData={pageContext}
    seoTitle={seo?.seoTitle}
    seoDescription={seo?.seoDescription}
    seoImage={seo?.seoImage?.seoImageUrl}
  >
    <VerticalContainer as={TextContainer}>
      <HeadingBig>{title}</HeadingBig>
      {structuredBody?.value && (
        <section>
          <StructuredText data={structuredBody} />
        </section>
      )}
    </VerticalContainer>
  </PageWrapper>
);

export default OtherPagesTemplate;

export const query = graphql`
  query OtherPagesQuery($locale: String!, $id: String!) {
    datoCmsOtherPage(locale: { eq: $locale }, originalId: { eq: $id }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      title
      structuredBody {
        blocks
        value
      }
    }
  }
`;
